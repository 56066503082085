import React from 'react';

const Article6 = ({ scrollPercentage }) => {
  return (
    <>
      <div className="banner-712">
        <div className="cs-container">
          <span className="cs-int-title">From Abandoned to Adored ✨</span>
        </div>
        <div className="scroll-line" style={{ width: `${scrollPercentage}%` }}></div>
        <picture className="cs-background">
          <source media="(max-width: 600px)" srcSet="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
          <source media="(min-width: 601px)" srcSet="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
          <img
            loading="lazy"
            decoding="async"
            src="/api/placeholder/1280/568"
            alt="Animal care background"
            width="1280"
            height="568"
            aria-hidden="true"
          />
        </picture>
      </div>

      <div className="site-wrap">
        <picture className="cs-picture">
          <source media="(max-width: 600px)" srcSet="/images/simba.jpg" />
          <source media="(min-width: 601px)" srcSet="/images/simba.jpg" />
          <img
            loading="lazy"
            decoding="async"
            alt="Headline in pink with the header written in white"
            src="/images/simba.jpg"
          />
        </picture>

        <p>
          We found Simba, a brave and gentle soul, abandoned in an uncompleted building in Osun State. His journey
          hasn't been easy, but he's come a long way. Now in our loving care in Lagos, Simba is ready to find his
          forever family! 😍
        </p>

        <p>
          Simba is: • Friendly and loves people • Playful and affectionate • Ready for a loving home
        </p>

        <p>
          Simba deserves a fresh start, and we'd love for you to be part of his new beginning. 🐾 Adoption is
          absolutely FREE - all we ask is for a loving heart and a commitment to care for him.
        </p>

        <p>
          Location: Lagos, Nigeria
        </p>

        <p>
          Interested in adopting Simba? DM us to learn more and arrange a meeting! Let's give Simba the life he
          deserves. 🐶❤️
        </p>

    
      </div>
    </>
  );
};

export default Article6;