import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Typography, Button, Row, Col, Spin} from "antd";
import { Link } from 'react-router-dom';
import Form from "./Form";
import { setLogin } from "state";
import { useDispatch } from "react-redux";

import {
  ArrowRightOutlined,
  UserOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { GoogleLogin } from '@react-oauth/google';
import './index.css'
const { Content } = Layout;

const LoginPage = ({ showLoginForm, setShowLoginForm }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const clientId = process.env.REACT_APP_CLIENT_ID;

  
  const [sessionToken, setSessionToken] = useState('');



  const handleLoginSuccess = async (credentialResponse) => {
    console.log(credentialResponse);
    try {
      const response = await fetch('https://havendogs.onrender.com/api/auth/google', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentialResponse),
      });

      if (response.ok) {
        const data = await response.json();
        // Handle the response data from the backend (e.g., save the JWT token)

        dispatch(
          setLogin({
            user: data.user,
            token: data.token,
          })
        );   
        navigate("/home");
        // Redirect the user to the protected page or perform any other action
      } else {
        console.error('Login failed');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const handleLoginFailure = (error) => {
    console.error('Google Login failed:', error);
  };

 

  const handleShowLoginForm = () => {
    setShowLoginForm(true);
  };
  const calculateFontSize = () => {
    const screenWidth = window.innerWidth;

    // Define breakpoints and corresponding font sizes
    const breakpoints = [576, 768];
    const fontSizes = [64, 80, 100]; // Adjust font sizes as needed

    // Find the appropriate font size based on the screen width
    for (let i = 0; i < breakpoints.length; i++) {
      if (screenWidth <= breakpoints[i]) {
        return fontSizes[i];
      }
    }

    // Default font size for larger screens
    return fontSizes[fontSizes.length - 1];
  };

  // Get the calculated font size
  const fontSize = calculateFontSize();

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // Add effects when the element comes into view
        entry.target.classList.add('show', 'fade-in', 'scale-in', 'slide-in-left', 'flip-in', 'blur-in','giggle', 'slide-in-right', 'flip-in');
  
        // Remove out effects (if previously applied)
      } else {
        // Remove in effects
        entry.target.classList.remove('show', 'fade-in', 'scale-in', 'slide-in-left', 'flip-in', 'blur-in','giggle', 'slide-in-right', 'flip-in');
  
      }
    });
  });

  useEffect(() => {
    const hiddenElements = document.querySelectorAll('.hidden');
    hiddenElements.forEach((el) => {
      const effectType = el.dataset.effect ; // Get the effect type from data-effect attribute, fallback to 'fade-in'
  console.log(effectType);
      // Check if element is initially visible
      const rect = el.getBoundingClientRect();
      if (rect.top < window.innerHeight && rect.bottom >= 0) {
        el.classList.add('show');

        el.classList.add(effectType);
      } else {
        observer.observe(el);
      }
    });
  }, []);
  return (
    <Layout>
      <Content>
      
        {!showLoginForm ? (
          <div>
         
          <section id="hero-219">
      <div className="cs-container hidden" data-effect="scale-in">
        <h1 className="cs-title"> Haven Pet Home and Animal Care Foundation</h1>
        <p className="cs-text">
        Rescue, Rehabilitate, Rehome: Giving Animals a Second Chance
                </p>
        <button
      icon={<ArrowRightOutlined />}
      onClick={handleShowLoginForm}
      className="get-started-button"
    >
      Get Started
    </button>
      </div>

      {/* Background Image */}
      <div className="cs-background">
        <img decoding="async" src="https://images.pexels.com/photos/10293954/pexels-photo-10293954.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="kitchen" width="2250" height="1500" aria-hidden="true" />
      </div>
      
    </section>
   
    
    <section id="sbsr-1475">
      <div className="cs-container">
        {/* Left Image Section */}
        <div className="cs-image-group">
          {/* Floating Box */}
          <div className="cs-box">
            {/* Blob */}
            <img className="cs-blob" loading="lazy" decoding="async" src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Graphics/blob-shape3.svg" alt="blob" width="254" height="238" aria-hidden="true" />
            <span className="cs-number">100%</span>
            <span className="cs-desc">Satisfaction Rating</span>
          </div>
          {/* Big Background Image */}
          <div className="cs-picture hidden"  data-effect="slide-in-right">
            {/* Mobile and Tablet Image */}
            <img loading="lazy" decoding="async" src="https://images.unsplash.com/photo-1575859225486-f377a3f867bf?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fHBldCUyMHBpbmt8ZW58MHx8MHx8fDA%3D" alt="girl" width="542" height="720" aria-hidden="true" />
          </div>
          {/* Oval */}
          <img className="cs-oval" loading="lazy" decoding="async" src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Graphics/oval.svg" alt="oval" width="727" height="480" aria-hidden="true" />
        </div>
        {/* Right Content Section */}
        <div className="cs-content">
  <span className="cs-topper hidden" data-effect="fade-in">About Haven Pet Home and Animal Care Foundation</span>
  <h2 className="cs-title hidden" data-effect="fade-in">Welcome to Your One-Stop Pet Care Destination</h2>
  <p className="cs-text hidden" data-effect="fade-in">At Haven Pet Home and Animal Care Foundation, we understand the unique needs of our beloved animal companions. Whether you're seeking pet sitting services, veterinary recommendations, or exploring pet adoption options, our dedicated team is here to provide you with comprehensive and compassionate solutions. We promise to treat your furry friends with the utmost care, ensuring their well-being and happiness.</p>
  <ul className="cs-ul hidden">
    <li className="cs-li hidden">
      <img className="cs-icon" aria-hidden="true" loading="lazy" decoding="async" src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Graphics/green-check.svg" alt="checkmark" width="24" height="24" />
      Experienced and trusted animal care professionals.
    </li>
    <li className="cs-li hidden">
      <img className="cs-icon" aria-hidden="true" loading="lazy" decoding="async" src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Graphics/green-check.svg" alt="checkmark" width="24" height="24" />
      Personalized services tailored to your pet's needs.
    </li>
    <li className="cs-li hidden">
      <img className="cs-icon" aria-hidden="true" loading="lazy" decoding="async" src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Graphics/green-check.svg" alt="checkmark" width="24" height="24" />
      Comprehensive animal care solutions, all in one place.
    </li>
    <li className="cs-li hidden">
      <img className="cs-icon" aria-hidden="true" loading="lazy" decoding="async" src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Graphics/green-check.svg" alt="checkmark" width="24" height="24" />
      Dedicated to promoting responsible pet ownership.
    </li>
    <li className="cs-li hidden">
      <img className="cs-icon" aria-hidden="true" loading="lazy" decoding="async" src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Graphics/green-check.svg" alt="checkmark" width="24" height="24" />
      Committed to the well-being of all animals.
    </li>
  </ul>
  <a className="cs-button-solid hidden" aria-label="learn more about our services" data-effect="giggle" href="">Learn More</a>
</div>
      </div>
    </section>    
    <section id="services-1354">
  <div className="cs-container">
    <div className="cs-content">
      <span className="cs-topper">Our Services</span>
      <h2 className="cs-title">Comprehensive Care for Your Furry Companions</h2>
    </div>
    <ul className="cs-card-group">
      <li className="cs-item hidden" data-effect='slideIn'>
        <div href="/" className="cs-link">
          <h3 className="cs-h3"><span className="cs-span">Pet Sitting</span> Services</h3>
        </div>
        <div className="cs-background">
          {/* Mobile and Tablet Image */}
          <img decoding="async" src="https://images.pexels.com/photos/6994718/pexels-photo-6994718.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pet sitting" width="305" height="305" aria-hidden="true" />
        </div>
      </li>
      <li className="cs-item hidden" data-effect='slideIn'>
        <div href="/" className="cs-link">
          <h3 className="cs-h3"><span className="cs-span">Veterinary</span> Recommendations</h3>
        </div>
        <div className="cs-background">
          {/* Mobile and Tablet Image */}
          <img decoding="async" src="https://images.pexels.com/photos/6259885/pexels-photo-6259885.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="veterinary recommendations" width="305" height="305" aria-hidden="true" />
        </div>
      </li>
      <li className="cs-item hidden" data-effect='slideIn'>
        <div href="/" className="cs-link">
          <h3 className="cs-h3"><span className="cs-span">Pet</span> Adoption</h3>
        </div>
        <div className="cs-background">
          {/* Mobile and Tablet Image */}
          <img decoding="async" src="https://images.unsplash.com/photo-1444212477490-ca407925329e?q=80&w=1528&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="pet adoption" width="305" height="305" aria-hidden="true" />
        </div>
      </li>
      <li className="cs-item hidden" data-effect='slideIn'>
        <div href="/" className="cs-link">
          <h3 className="cs-h3"><span className="cs-span">Pet</span> Education</h3>
        </div>
        <div className="cs-background">
          {/* Mobile and Tablet Image */}
          <img decoding="async" src="https://images.unsplash.com/photo-1507146426996-ef05306b995a?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="pet education" width="305" height="305" aria-hidden="true" />
        </div>
      </li>
    </ul>
  </div>
</section>

<section id="steps-1171">
  <div className="cs-container">
    <div className="cs-content">
      <span className="cs-topper">Our Process</span>
      <h2 className="cs-title hidden" data-effect="fade-in">How to Get Started with Our Services</h2>
      <p className="cs-text">At Haven Pet Home and Animal Care Foundation, we strive to make the process of caring for your furry friends as seamless and convenient as possible.</p>
    </div>
    <ul className="cs-card-group">
      <li className="cs-item">
        <div className="cs-picture">
          <img className="cs-icon hidden" data-effect="fade-in" src="svg/identify.svg" alt="icon" width="40" height="40" loading="lazy" decoding="async" aria-hidden="true" />
        </div>
        <h3 className="cs-h3">Identify Your Needs</h3>
        <p className="cs-item-p">Whether you require pet sitting, veterinary recommendations, or are interested in pet adoption, let us know your specific requirements.</p>
      </li>
      <li className="cs-item">
        <div className="cs-picture">
          <img className="cs-icon hidden" data-effect="fade-in" src="svg/puzzle.svg" alt="icon" width="40" height="40" loading="lazy" decoding="async" aria-hidden="true" />
        </div>
        <h3 className="cs-h3">Personalized Solution</h3>
        <p className="cs-item-p">Our team will work closely with you to understand your pet's unique needs and provide a tailored solution.</p>
      </li>
      <li className="cs-item">
        <div className="cs-picture">
          <img className="cs-icon hidden" data-effect="fade-in" src="svg/personalized.svg" alt="icon" width="40" height="40" loading="lazy" decoding="async" aria-hidden="true" />
        </div>
        <h3 className="cs-h3">Professional Care</h3>
        <p className="cs-item-p">Our experienced and caring professionals will provide top-notch care for your beloved pets, ensuring their well-being at all times.</p>
      </li>
      <li className="cs-item">
        <div className="cs-picture">
          <img className="cs-icon hidden" data-effect="fade-in" src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons/box.svg" alt="icon" width="40" height="40" loading="lazy" decoding="async" aria-hidden="true" />
        </div>
        <h3 className="cs-h3">Continued Support</h3>
        <p className="cs-item-p">We are dedicated to providing ongoing support and guidance to ensure the best possible experience for you and your furry companions.</p>
      </li>
    </ul>
  </div>
  {/* Tire Screech */}
  <img className="cs-graphic" src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Graphics/tire-mark.svg" alt="icon" width="840" height="357" loading="lazy" decoding="async" aria-hidden="true" />
</section>
   <section id="stats-888">
  <div className="cs-container">
    <div className="cs-content">
      <span className="cs-topper">Our Mission</span>
      <h2 className="cs-title hidden" data-effect="scale-in">Dedicated to Improving the Lives of Pets</h2>
      <p className="cs-text">At Haven Pet Home and Animal Care Foundation, our mission is to provide comprehensive and compassionate care for all animals. We believe that every pet deserves a loving home, proper medical attention, and a safe environment to thrive.</p>
      <p className="cs-text">Our dedicated team of professionals works tirelessly to ensure the well-being of every animal that comes into our care, promoting responsible pet ownership and advocating for animal welfare.</p>
      <a href="#initiatives" className="cs-button-solid">Get Involved</a>
    </div>
    <ul className="cs-card-group">
      <li className="cs-item">
        <span className="cs-number">100+</span>
        <span className="cs-desc">Pets Adopted <br />Annually</span>
      </li>
      <li className="cs-item" style={{backgroundColor:'#FF70AB'}}>
        <span className="cs-number">500+</span>
        <span className="cs-desc">Pets Cared For <br />Through Our Services</span>
      </li>
      <li className="cs-item" style={{backgroundColor:'#FF70AB'}}>
        <span className="cs-number">20+</span>
        <span className="cs-desc">Veterinary Partners <br />Recommended</span>
      </li>
      <li className="cs-item">
        <span className="cs-number">5</span>
        <span className="cs-desc">Community Awards <br />Received</span>
      </li>
    </ul>
  </div>
</section>

    <section id="logos-572">
      <div className="cs-container">
        {/* Ideally, you want to use SVGs for the file path image */}
        <img className="cs-logo" src="images/sourcewise-Logo.png" alt="logo" width="287" height="65" loading="lazy" decoding="async" />
        <img className="cs-logo" src="images/wytewales.jpg" alt="logo" width="235" height="53" loading="lazy" decoding="async" />
        <img className="cs-logo" src="images/Olantii Logo.png" alt="logo" width="229" height="55" loading="lazy" decoding="async" />
        <img className="cs-logo" src="images/exoticpetcafe.jpg" alt="logo" width="228" height="61" loading="lazy" decoding="async" />
      </div>
    </section>

    <section id="reviews-727">
  <div className="cs-container">
    <div className="cs-content" >
      <span className="cs-topper hidden" data-effect="slide-in-right" >Testimonials</span>
      <h2 className="cs-title hidden" data-effect="slide-in-left" >Trusted by Pet Owners and Animal Lovers</h2>
      <p className="cs-text hidden" data-effect="scale-in" >At Haven Pet Home and Animal Care Foundation, we take pride in providing exceptional services that have positively impacted the lives of countless pets and their owners. Hear what some of our satisfied clients have to say about their experience with us.</p>
    </div>
    <ul className="cs-card-group">
      <li className="cs-item hidden" data-effect="flip-in" >
        <img className="cs-icon" src="images/pinkquote.png" alt="quote icon" aria-hidden="true" width="40" height="38" loading="lazy" decoding="async" />
        <h3 className="cs-h3">Compassionate Care</h3>
        <p className="cs-item-text">The team at Haven Pet Home and Animal Care Foundation truly cares about the well-being of animals. They treated my furry friend with kindness and professionalism, ensuring their comfort and happiness throughout their stay.</p>
        <span className="cs-name">Abiola, Pet Owner</span>
      </li>
      <li className="cs-item hidden" data-effect="slide-in-right" >
        <img className="cs-icon" src="images/pinkquote.png" alt="quote icon" aria-hidden="true" width="40" height="38" loading="lazy" decoding="async" />
        <h3 className="cs-h3">Excellent Service</h3>
        <p className="cs-item-text">I was thoroughly impressed with the level of service and attention to detail provided by Haven Pet Home and Animal Care Foundation. They went above and beyond to meet my pet's needs, and I couldn't be happier with their services.</p>
        <span className="cs-name">Naomi
        , Pet Owner</span>
      </li>
    </ul>
  </div>
</section>

    <section id="blog-846">
      <div className="cs-container">
        <div className="cs-content">
          <span className="cs-topper">Blog</span>
          <h2 className="cs-title">Latest News & Articles</h2>
        </div>
        <ul className="cs-card-group">
          <li className="cs-item">
            <picture className="cs-picture">
              <source media="(max-width: 600px)" srcSet="/images/pinnock.jpg" />
              <source media="(min-width: 601px)" srcSet="/images/pinnock.jpg" />
              <img loading="lazy" decoding="async" alt='headline in pink with the header written in white ' src="/images/pinnock.jpg" />
            </picture>
            <div className="cs-item-content">
              <span className="cs-date">7 Nov, 2024</span>
              <h3 className="cs-h3">Statement from Haven Pet and Animal Care Foundation on the Recent Incident at Pinnock Estate, Lekki</h3>
              <p className="cs-item-text">
              We at Haven Pet and Animal Care Foundation would like to address the recent tragic incident involving two dogs in Pinnock Estate, Lekki, where a man sadly lost his life.   
                              </p>
              <Link to="/Pinnock Estate Alleged Dog Mauling Incident" className="cs-link">
      Read More
      <img
        className="cs-icon"
        src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons%2Forange-carrot-right.svg"
        alt="arrow right"
        width="20"
        height="20"
        loading="lazy"
        decoding="async"
        aria-hidden="true"
      />
    </Link>
            </div>
          </li>
          <li className="cs-item">
            <picture className="cs-picture">
              <source media="(max-width: 600px)" srcSet="/images/simba.jpg" />
              <source media="(min-width: 601px)" srcSet="/images/simba.jpg" />
              <img loading="lazy" decoding="async" src="/images/simba.jpg" alt="therapy" width="369" height="290" />
            </picture>
            <div className="cs-item-content">
              <span className="cs-date">6 Nov, 2024</span>
              <h3 className="cs-h3">
               Meet Simba - Looking for a Forever Home! 
               </h3>
              <p className="cs-item-text">
              We found Simba, a brave and gentle soul, abandoned in an uncompleted building in Osun State. His journey hasn't been easy, but he's come a long way. Now in our loving care in Lagos, Simba is ready to find his forever family!              </p>
              <Link to="/Meet Simba - Looking for a Forever Home!" className="cs-link">
      Read More
      <img
        className="cs-icon"
        src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons%2Forange-carrot-right.svg"
        alt="arrow right"
        width="20"
        height="20"
        loading="lazy"
        decoding="async"
        aria-hidden="true"
      />
    </Link>
            </div>
          </li>
          <li className="cs-item">
            <picture className="cs-picture">
              <source media="(max-width: 600px)" srcSet="https://images.unsplash.com/photo-1554322538-53e66c5958a9?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
              <source media="(min-width: 601px)" srcSet="https://images.unsplash.com/photo-1554322538-53e66c5958a9?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
              <img loading="lazy" decoding="async" src="https://images.unsplash.com/photo-1554322538-53e66c5958a9?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="therapy" width="369" height="290" />
            </picture>
            <div className="cs-item-content">
              <span className="cs-date">15 Apr, 2024</span>
              <h3 className="cs-h3">Why You Should Adopt, Not Shop: The Benefits of Pet Adoption</h3>
              <p className="cs-item-text">
              Choosing to adopt a pet rather than buying one is a compassionate and responsible decision. With millions of animals in shelters waiting for loving homes,...
              </p>
              <Link to="/Why You Should Adopt, Not Shop: The Benefits of Pet Adoption " className="cs-link">
      Read More
      <img
        className="cs-icon"
        src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons%2Forange-carrot-right.svg"
        alt="arrow right"
        width="20"
        height="20"
        loading="lazy"
        decoding="async"
        aria-hidden="true"
      />
    </Link>
            </div>
          </li>
          <li className="cs-item">
            <picture className="cs-picture">
              <source media="(max-width: 600px)" srcSet="https://images.unsplash.com/photo-1583786693544-e352f898888d?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3Dhttps://images.unsplash.com/photo-1507145569372-d69bae8bc9a0?q=80&w=1373&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3Dhttps://images.unsplash.com/photo-1558618047-f4b511aae74d?q=80&w=1471&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
              <source media="(min-width: 601px)" srcSet="https://images.unsplash.com/photo-1583786693544-e352f898888d?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3Dhttps://images.unsplash.com/photo-1507145569372-d69bae8bc9a0?q=80&w=1373&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3Dhttps://images.unsplash.com/photo-1558618047-f4b511aae74d?q=80&w=1471&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
              <img loading="lazy" decoding="async" src="https://images.unsplash.com/photo-1583786693544-e352f898888d?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3Dhttps://images.unsplash.com/photo-1507145569372-d69bae8bc9a0?q=80&w=1373&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3Dhttps://images.unsplash.com/photo-1558618047-f4b511aae74d?q=80&w=1471&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="therapy" width="369" height="290" />
            </picture>
            <div className="cs-item-content">
              <span className="cs-date">07 Sep, 2022</span>
              <h3 className="cs-h3">The Role of Rescue Shelters in Animal Welfare</h3>
              <p className="cs-item-text">
                Physical therapy is often an important part of the recovery process for patients who have undergone surgery.
              </p>
              <Link to="/article4" className="cs-link">
      Read More
      <img
        className="cs-icon"
        src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons%2Forange-carrot-right.svg"
        alt="arrow right"
        width="20"
        height="20"
        loading="lazy"
        decoding="async"
        aria-hidden="true"
      />
    </Link>
            </div>
          </li>
          {/* Repeat the above structure for other blog items */}
        </ul>
        {/* <a href="" className="cs-button-solid">View All News</a> */}
      </div>
    </section>


</div>
        ) : (
          <div
           className="login-container"
          >
       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',
 
  marginTop:'25%'}}>
       <GoogleLogin
        clientId={clientId}
        onSuccess={handleLoginSuccess}
        onFailure={handleLoginFailure}
      />
    </div>
{/* <a href="/auth/google" role="button" onClick={handleGoogleSignIn}>
      Sign Up With Google
    </a>    */}
             <Typography.Title
              level={5}
              style={{ fontWeight: "500", marginBottom: "1.5rem" }}
            >
              Login to a Haven Animal Advocacy And Shelter account
            </Typography.Title>
            <Form />
          </div>
        )}
      </Content>
    </Layout>
  );
};
;

export default LoginPage;
