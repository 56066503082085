import React, { useState, useEffect } from 'react';
import './NavBar.css';
import { Link } from 'react-router-dom';
import zIndex from '@mui/material/styles/zIndex';
import Donate from './Donate';
const Navigation = ({ showLoginForm, setShowLoginForm }) => {
  const [isNavActive, setIsNavActive] = useState(false);
  const [isBodyOpen, setIsBodyOpen] = useState(false);
  const [isAriaExpanded, setIsAriaExpanded] = useState(false);
  const [activeDropdowns, setActiveDropdowns] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleShowLoginForm = () => {
    setShowLoginForm(true);
  };
  const toggleNav = () => {
    setIsNavActive(!isNavActive);
    setIsBodyOpen(!isBodyOpen);
    setIsAriaExpanded(!isAriaExpanded);
  };

  const toggleDropdown = (index) => {
    setActiveDropdowns((prev) => {
      const newActiveDropdowns = [...prev];
      console.log(newActiveDropdowns);
      newActiveDropdowns[index] = !newActiveDropdowns[index];
      return newActiveDropdowns;
    });
  };

  const handleNavItemClick = (index) => {
    setActiveIndex(index);
    setActiveDropdowns([]);
 setIsNavActive(false); 
 setIsBodyOpen(false);
     setIsAriaExpanded(false);

  };

  useEffect(() => {
    document.body.classList.toggle('cs-open', isBodyOpen);
  }, [isBodyOpen]);

  return (
    <header id="cs-navigation" className={isNavActive ? 'cs-active' : ''} >
      <div className="cs-container">
        <a style={{zIndex:'-10000' }} href="/" className="cs-logo" aria-label="back to home">
          <img
            src="/svg/logo.svg"
            alt="logo"
            className="cs-logo-image"
            aria-hidden="true"
            decoding="async"
          />
        </a>
        <nav className="cs-nav" role="navigation">
          <button
            className="cs-toggle"
            aria-label="mobile menu toggle"
            onClick={toggleNav}
          >
            <div className="cs-box" aria-hidden="true">
              <span className="cs-line cs-line1" aria-hidden="true"></span>
              <span className="cs-line cs-line2" aria-hidden="true"></span>
              <span className="cs-line cs-line3" aria-hidden="true"></span>
            </div>
          </button>
          <div className="cs-ul-wrapper" style={{zIndex:'10000'}}>
  <div id="cs-expanded" className="cs-ul" aria-expanded={isAriaExpanded}>
    <div className="cs-li">
      <Link to="/home" className={`cs-li-link ${activeIndex === 0 ? 'cs-active' : ''}`} onClick={() => handleNavItemClick(0)}>
        HOME
      </Link>
      <div className={`cs-dropdown ${activeDropdowns[0] ? 'cs-active' : ''}`} onClick={() => toggleDropdown(0)}>
        <span className="cs-dropdown-toggle" aria-expanded={activeDropdowns[0]}></span>
      </div>
    </div>
    <div className="cs-li">
      <Link to="/blog" className={`cs-li-link ${activeIndex === 6 ? 'cs-active' : ''}`} onClick={() => handleNavItemClick(6)}>
        BLOG
      </Link>
      <div className={`cs-dropdown ${activeDropdowns[6] ? 'cs-active' : ''}`} onClick={() => toggleDropdown(6)}>
        <span className="cs-dropdown-toggle" aria-expanded={activeDropdowns[6]}></span>
      </div>
    </div>
   

    <div className="cs-li">
      <Link to="/services" className={`cs-li-link ${activeIndex === 1 ? 'cs-active' : ''}`} onClick={() => handleNavItemClick(1)}>
        SERVICES
      </Link>
      <div className={`cs-dropdown ${activeDropdowns[1] ? 'cs-active' : ''}`} onClick={() => toggleDropdown(1)}>
        <span className="cs-dropdown-toggle" aria-expanded={activeDropdowns[1]}></span>
      </div>
    </div>
   

    <div className="cs-li">
      <Link to="/about-us" className={`cs-li-link ${activeIndex === 2 ? 'cs-active' : ''}`} onClick={() => handleNavItemClick(2)}>
        ABOUT US
      </Link>
      <div className={`cs-dropdown ${activeDropdowns[2] ? 'cs-active' : ''}`} onClick={() => toggleDropdown(2)}>
        <span className="cs-dropdown-toggle" aria-expanded={activeDropdowns[2]}></span>
      </div>
    </div>
    <div className="cs-li cs-mobile-donate">
      <Link to="/https://paystack.com/pay/s96g0g5t6b" className={`cs-li-link cs-nav-button ${activeIndex === 3 ? 'cs-active' : ''}`} onClick={() => handleNavItemClick(3)}>
                  DONATE
                </Link>
      <div className={`cs-dropdown ${activeDropdowns[3] ? 'cs-active' : ''}`} onClick={() => toggleDropdown(3)}>
        <span className="cs-dropdown-toggle" aria-expanded={activeDropdowns[3]}></span>
      </div>
    </div>
    <div className="cs-li">
      <Link className={`cs-li-link ${activeIndex === 7 ? 'cs-active' : ''}`} onClick={() =>handleShowLoginForm()}>
        LOGIN
      </Link>
      <div className={`cs-dropdown ${activeDropdowns[7] ? 'cs-active' : ''}`} onClick={() => toggleDropdown(7)}>
        <span className="cs-dropdown-toggle" aria-expanded={activeDropdowns[7]}></span>
      </div>
    </div>
    <div className="cs-li">
      <div className={`cs-dropdown ${activeDropdowns[5] ? 'cs-active' : ''}`} onClick={() => toggleDropdown(5)}>
        <span className="cs-dropdown-toggle" aria-expanded={activeDropdowns[5]}></span>
      </div>
    </div>
   
  
  
  </div>
</div>
        </nav>
        <Link to="/https://paystack.com/pay/s96g0g5t6b" className="cs-button-solid cs-nav-button  cs-desktop-donate">
                DONATE
              </Link>
        {/* Uncomment the below block to enable dark mode toggle */}
        {/* <button id="dark-mode-toggle" aria-label="dark mode toggle">
          <svg
            className="cs-moon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 480 480"
            style={{ enableBackground: 'new 0 0 480 480' }}
            xmlSpace="preserve"
          >
            <path d="M459.782 347.328c-4.288-5.28-11.488-7.232-17.824-4.96-17.76 6.368-37.024 9.632-57.312 9.632-97.056 0-176-78.976-176-176 0-58.4 28.832-112.768 77.12-145.472 5.472-3.712 8.096-10.4 6.624-16.832S285.638 2.4 279.078 1.44C271.59.352 264.134 0 256.646 0c-132.352 0-240 107.648-240 240s107.648 240 240 240c84 0 160.416-42.688 204.352-114.176 3.552-5.792 3.04-13.184-1.216-18.496z" />
          </svg>
          <img
            className="cs-sun"
            aria-hidden="true"
            src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons%2Fsun.svg"
            decoding="async"
            alt="moon"
            width="15"
            height="15"
          />
        </button> */}
      </div>
    </header>
  );
};

export default Navigation;