import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './BlogMenu.css'

const blogItems = [
  {
    date: '20 Sep, 2024',
    title: 'Meet Blackie: A Tale of Rescue and Hope',
    text: 'At Haven Dogs, every rescue has a story. Today, we`d like to introduce you to Blackie, a resilient 6-month-old pup who recently joined our family. Found in a carpenter`s shop in Lagos, Blackie had gone days without food. Thanks to our team`s swift action, she`s now healthy and seeking her forever home...',
    imgSrc: 'images/blackiepic.jpg',
    link: '/blog/meet-blackie',
    tags: ['Dog Rescue', 'Adoption', 'Animal Welfare'],
    author: {
      name: 'Haven Dogs Team',
      role: 'Rescue Team',
      imgSrc: 'svg/logo.svg'
    }
  },
  {
    date: '3 Aug, 2024',
    title: 'Whiskers and Second Chances: A Tale of Cat Rescue',
    text: 'In a dimly lit alley, we discovered a colony of cats struggling to survive. This is the heartwarming journey of how we rescued, rehabilitated, and rehomed these feline friends...',
    imgSrc: 'https://images.unsplash.com/photo-1513360371669-4adf3dd7dff8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80',
    link: '/blog/cat-rescue',
    tags: ['Cat Rescue', 'Animal Welfare'],
    author: {
      name: 'Haven Dogs Team',
      role: 'Rescue Team',
      imgSrc: 'svg/logo.svg'
    }
  },
  {
    date: '15 Jul, 2024',
    title: 'Top Tips for First-Time Horse Owners in Nigeria',
    text: ' Whether you`re a first-time horse owner or looking to improve your horse care routine, these top tips will help you provide the best care for your horse...',
    imgSrc: 'images/horses.jpg',
    link: '/blog/horse-ownership-tips',
    tags: ['Horse Care', 'Pet Ownership', 'Animal Welfare'],
    author: {
      name: 'Haven Dogs Team',
      role: 'Pet Care Experts',
      imgSrc: 'svg/logo.svg'
    }
  },
  {
    date: '23 Jul, 2024',
    title: 'Welcome Rafah to Haven Dogs: Top Tips for New Horse Owners',
    text: 'We are thrilled to announce a new addition to the Haven Dogs family – Rafah, a beautiful 2-year-old male horse from Gombe State, Nigeria. Rafah`s arrival presents an opportunity to share valuable insights with his new family and other aspiring horse owners...',
    imgSrc: 'images/rafa-horse.jpg',
    link: '/blog/welcome-rafah-horse-tips',
    tags: ['Horse Care', 'Pet Ownership', 'Haven Dogs News', 'Animal Welfare'],
    author: {
      name: 'Haven Dogs Team',
      role: 'Equine Care Specialists',
      imgSrc: 'svg/logo.svg'
    }
  },

];
const BlogSection = () => {
  const [selectedTag, setSelectedTag] = useState('All');

  const handleTagClick = (tag) => {
    setSelectedTag(tag);
  };

  const filteredItems = selectedTag === 'All'
    ? blogItems
    : blogItems.filter(item => item.tags.includes(selectedTag));

  // Get unique tags from all blog items
  const allTags = ['All', ...new Set(blogItems.flatMap(item => item.tags))];

  return (
    <section id="blog-1540" className="cs-container">
      <div className="cs-content">
        <span className="cs-topper">Latest Stories</span>
        <h2 className="cs-title">Animal Rescue Chronicles</h2>
        <p className="cs-text">
          Join us in our mission to rescue, rehabilitate, and rehome animals in need. Every story is a journey of hope and compassion.
        </p>
      </div>

      <div className="tag-container">
        {allTags.map(tag => (
          <button
            key={tag}
            onClick={() => handleTagClick(tag)}
            className={`tag-button ${selectedTag === tag ? 'active' : ''}`}
          >
            {tag}
          </button>
        ))}
      </div>

      <ul className="cs-card-group">
        {filteredItems.map((item, index) => (
          <li key={index} className="cs-item">
            <Link to={item.link} className="cs-link">
              <div className="cs-picture-group">
                <picture className="cs-picture">
                  <source media="(max-width: 600px)" srcSet={item.imgSrc} />
                  <source media="(min-width: 601px)" srcSet={item.imgSrc} />
                  <img loading="lazy" decoding="async" src={item.imgSrc} alt={item.title} width="365" height="201" />
                </picture>
              </div>
              <div className="cs-info">
                <div className="cs-flex">
                  <span className="cs-tag">{item.tags[0]}</span>
                  <span className="cs-date">
                    <img className="cs-icon" loading="lazy" decoding="async" src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Icons/calander.svg" alt="calendar icon" width="24" height="24" />
                    {item.date}
                  </span>
                </div>
                <h3 className="cs-h3">{item.title}</h3>
                <p className="cs-item-text">{item.text}</p>
                <div className="cs-bottom">
                  <div className="cs-author-group">
                    <picture className="cs-profile">
                      <img src={item.author.imgSrc} decoding="async" alt={item.author.name} width="50" height="50" />
                    </picture>
                    <span className="cs-name">
                      {item.author.name}
                      <span className="cs-job">{item.author.role}</span>
                    </span>
                  </div>
                  <picture className="cs-wrapper">
                    <img className="cs-arrow" loading="lazy" decoding="async" src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Icons/grey-right-chevron.svg" alt="right arrow" width="24" height="24" />
                  </picture>
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default BlogSection;