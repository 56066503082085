import React, { useState } from 'react';
import { PaystackButton } from 'react-paystack';
import './Donate.css'
const Donate = () => {
  const publicKey = 'pk_test_6e3d79e813c5d156fee255358855572e2cd71812';
  const [amount, setAmount] = useState(10);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [showThankYou, setShowThankYou] = useState(false);

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleAmountIncrement = (increment) => {
    setAmount((prevAmount) => prevAmount + increment);
  };

  const componentProps = {
    email,
    amount: amount * 140000, // Convert $ to Naira
    metadata: {
      name,
      phone,
    },
    publicKey,
    text: 'Donate',
    onSuccess: () => setShowThankYou(true),
    onClose: () => alert("Wait! Don't leave :("),
  };

  return (
    <div className="donate">
      <div className="container">
        <div className="appeals">
          <h2>Help Us Care for Our Furry Friends</h2>
          <p>
            At Have Pet Home and Animal Care Foundation, we strive to provide a safe and loving
            environment for abandoned and stray animals. Your donation will help
            us provide food, shelter, and medical care for these precious
            creatures.
          </p>
          <p>
            Every contribution, no matter how small, makes a difference in the
            lives of these animals. Thank you for your support!
          </p>
        </div>
        <div className="item">
          <div className="item-details">
            <p>Donation</p>
            <p>${amount} ({(amount * 1400).toLocaleString()} Naira)</p>
          </div>
        </div>
        <div className="checkout-form">
          <form>
            <label>Name</label>
            <input
              type="text"
              id="name"
              onChange={(e) => setName(e.target.value)}
            />
            <label>Email</label>
            <input
              type="text"
              id="email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <label>Phone</label>
            <input
              type="text"
              id="phone"
              onChange={(e) => setPhone(e.target.value)}
            />
            <label>Amount (in $)</label>
            <input
              type="number"
              id="amount"
              value={amount}
              onChange={handleAmountChange}
              step="0.01"
              min="0"
            />
            <div className="amount-increments">
              <button type="button" onClick={() => handleAmountIncrement(10)}>
                +$10
              </button>
              <button type="button" onClick={() => handleAmountIncrement(100)}>
                +$100
              </button>
              <button type="button" onClick={() => handleAmountIncrement(500)}>
                +$500
              </button>
              <button type="button" onClick={() => handleAmountIncrement(1000)}>
                +$1000
              </button>
            </div>
          </form>
          <PaystackButton className='paystack-button'{...componentProps} />
        </div>
      </div>
      {showThankYou && (
        <div className="thank-you-overlay">
          <div className="thank-you-content">
            <h2>Thank You for Your Generous Donation!</h2>
            <p>
              Your contribution will make a significant impact in the lives of
              our furry friends. We are grateful for your support!
            </p>
            <button onClick={() => setShowThankYou(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Donate;